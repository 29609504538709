import { For, Match, Switch, useContext } from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import { gql } from "@solid-primitives/graphql";
import { Icon } from "solid-heroicons";
import { arrowPath, exclamationCircle } from "solid-heroicons/outline";
import { createGraphQLClient } from "../../helpers/graphql";
import { UserContext } from "../../components/UserContext";

const _ListDatasetsQuery = gql`
  query listDatasetsQuery {
    listDataset {
      items {
        annotationType
        id
        name
      }
    }
  }
`;

enum AnnotationTypes {
  Box = "BoxAnnotation",
  Measurement = "MeasurementAnnotation",
  SingleLabel = "SingleLabelClassificationAnnotation",
}

function SpinnerIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="animate-spin h-6 inline-block mr-2 w-6"
      path={arrowPath}
    />
  );
}

function ErrorIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="h-6 inline-block mr-2 w-6"
      path={exclamationCircle}
    />
  );
}

interface DatasetRowProps {
  annotationType: AnnotationTypes;
  id: string;
  name: string;
}

function DatasetRow(props: DatasetRowProps) {
  const [t] = useI18n();

  return (
    <tr>
      <td class="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
        {props.name}
      </td>
      <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
        <Switch fallback="?">
          <Match when={props.annotationType === AnnotationTypes.Box}>
            {t("box", {}, "Box")}
          </Match>
          <Match when={props.annotationType === AnnotationTypes.Measurement}>
            {t("measurement", {}, "Measurement")}
          </Match>
          <Match when={props.annotationType === AnnotationTypes.SingleLabel}>
            {t("singleLabel", {}, "Single label")}
          </Match>
        </Switch>
      </td>
      <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
        <a
          href={`/annotation/${props.id}`}
          class="text-indigo-600 hover:text-indigo-900"
        >
          {t("annotate", {}, "Annotate")}
        </a>
      </td>
    </tr>
  );
}

export default function AnnotationPage() {
  const [t] = useI18n();
  const user = useContext(UserContext);
  const query = createGraphQLClient(user?.accessToken);

  // { refetch: refetchDatasets }]
  const [datasetsList] = query<{
    listDataset: {
      items: {
        annotationType: AnnotationTypes;
        id: string;
        name: string;
      }[];
    };
  }>(_ListDatasetsQuery);

  return (
    <div class="min-h-full">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {t("datasetsToAnnotate", {}, "Datasets to annotate")}
            </h1>
          </div>
        </header>

        <main>
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="px-4 py-8 sm:px-0">
              <div class="mt-8 flex flex-col">
                <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle">
                    <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table
                        class="min-w-full border-separate"
                        style={{
                          "border-spacing": "0",
                        }}
                      >
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                            >
                              {t("name", {}, "Name")}
                            </th>
                            <th
                              scope="col"
                              class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                            >
                              {t("type", {}, "Type")}
                            </th>
                            <th
                              scope="col"
                              class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                            />
                          </tr>
                        </thead>
                        <tbody class="bg-white">
                          <Switch
                            fallback={
                              <For each={datasetsList()!.listDataset.items}>
                                {(dataset) => (
                                  <DatasetRow
                                    annotationType={dataset.annotationType}
                                    id={dataset.id}
                                    name={dataset.name}
                                  />
                                )}
                              </For>
                            }
                          >
                            <Match when={datasetsList.error}>
                              <tr>
                                <td colspan="6">
                                  <div class="mx-auto my-2 py-1 rounded-full text-center">
                                    <ErrorIcon />
                                    Data could not be loaded [TODO Retry button]
                                  </div>
                                </td>
                              </tr>
                            </Match>
                            <Match when={datasetsList.loading}>
                              <tr>
                                <td colspan="6">
                                  <div class="bg-nl-violet-900 mx-auto my-2 py-1 rounded-full text-center text-white w-48">
                                    <SpinnerIcon />
                                    {t("loading", {}, "Loading")}...
                                  </div>
                                </td>
                              </tr>
                            </Match>
                          </Switch>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
