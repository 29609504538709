import { Auth } from "aws-amplify";
import { useNavigate } from "@solidjs/router";
import { onMount } from "solid-js";

import SignUpForm from "./components/SignUpForm";

function LoginPage() {
  const navigate = useNavigate();

  onMount(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user);
        navigate("/landing", { replace: true });
      })
      .catch((err) => console.warn(err));
  });

  return <SignUpForm />;
}

export default LoginPage;
