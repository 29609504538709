export function getAmplifyConfig() {
  const region = import.meta.env.VITE_API_REGION;
  const userPoolId = import.meta.env.VITE_API_USER_POOL_ID;
  const userPoolClientId = import.meta.env.VITE_API_USER_POOL_CLIENT_ID;

  if (!region) {
    throw new Error("API REGION missing (VITE_API_REGION)");
  } else if (!userPoolId) {
    throw new Error("API User Pool ID missing (VITE_API_USER_POOL_ID)");
  } else if (!userPoolClientId) {
    throw new Error(
      "API User Pool Client ID missing (VITE_API_USER_POOL_CLIENT_ID)",
    );
  }

  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: region,

      userPoolId: userPoolId,
      userPoolWebClientId: userPoolClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS
      // resources or not.
      mandatorySignIn: true,

      // OPTIONAL - Manually set the authentication flow type.
      // Default is "USER_SRP_AUTH".
      authenticationFlowType: "USER_SRP_AUTH",
    },
  };
}
