import { Auth } from "aws-amplify";
// import { useNavigate } from "@solidjs/router";
import { onMount } from "solid-js";
import { SignInForm } from "./components/SignInForm";

export default function SignInPage() {
  // const navigate = useNavigate();

  onMount(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("is signed in", user);
        // navigate("/dashboard", { replace: true });
      })
      .catch((err) => console.warn(err));
  });

  return <SignInForm />;
}
