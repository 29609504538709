export enum AnnotationOriginStatus {
  HumanGenerated = "humanGenerated",
  AiGenerated = "aiGenerated",
  AiGeneratedHumanApproved = "aiGeneratedHumanApproved",
  AiGeneratedHumanRejected = "aiGeneratedHumanRejected",
}

export enum AnnotationValuation {
  Good = "Good",
  Bad = "Bad",
  Neutral = "Neutral",
}

export enum AnnotationTypes {
  Box = "BoxAnnotation",
  SingleLabel = "SingleLabelClassificationAnnotation",
  Measurement = "MeasurementAnnotation",
}

export type Box = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type UpdateImageAnnotationInput = {
  ImageAnnotation_belongsToImageContainerID: string;
  annotationData: {
    annotationType: AnnotationTypes;
    box: Box;
    singleLabelClassification: string;
  };
};

export type DatasetMinimal = {
  annotationType: AnnotationTypes;
  classes: {
    items: {
      color: string;
      id: string;
      name: string;
    }[];
  };
  id: string;
  name: string;
};

export type Annotation = {
  annotationData: {
    annotationType: AnnotationTypes;
    box: Box;
    measurement: {
      extraOutputData: string;
      value: number;
    };
    singleLabelClassification: {
      label: {
        color: string;
        id: string;
        name: string;
      };
      confidence: number;
    };
  };
  createdAt: string;
  id: string;
  needsReview: boolean;
  originStatus: AnnotationOriginStatus;
  updatedAt: string;
};

export type ImageContainer = {
  annotationComplete: boolean | undefined;
  annotations: {
    items: Annotation[];
  };
  createdAt: string;
  dataset: DatasetMinimal;
  id: string;
  imageKey: string;
  name: string;
  needsReview: boolean;
  source: {
    id: string;
    name: string;
  };
  updatedAt: string;
  smallURL: string;
  largeURL: string;
  originalURL: string;
};

export type User = {
  accessToken: string;
  email: string | undefined;
  userName: string;
  refetchUserData: any;
};

export enum AuthSignInErrorCode {
  USER_NOT_CONFIRMED = "UserNotConfirmedException",
  USER_NOT_FOUND = "UserNotFoundException",
  USER_NOT_AUTHORIZED = "NotAuthorizedException",
  USER_REQUIRES_PASSWORD_RESET = "PasswordResetRequiredException",
}

export enum AuthForgotPasswordErrorCode {
  USER_NOT_FOUND = "UserNotFoundException",
}

export enum AuthForgotPasswordSubmitErrorCode {
  USER_NOT_FOUND = "UserNotFoundException",
  EXPIRED_CODE = "ExpiredCodeException",
  MISMATCHED_CODE = "CodeMismatchException",
}

export type GetDatasetType = {
  getDataset: {
    annotationType: AnnotationTypes;
    classes: {
      items: {
        color: string;
        id: string;
        name: string;
      }[];
    };
    id: string;
    images: {
      items: ImageContainer[];
    };
    name: string;
  };
};
