import { createEffect, For, Show, useContext } from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import { gql } from "@solid-primitives/graphql";
import TaskOverview from "./components/TaskOverview";
import { UserContext } from "../../components/UserContext";
import { createGraphQLClient } from "../../helpers/graphql";

type TaskList = {
  listTask: {
    items: {
      createdAt: string;
      description: {
        description: string;
      };
      id: string;
      imageKey: string | null;
      machine: {
        id: string;
        name: string;
        status: number;
      };
      MachineID: string | null;
      ModelID: string | null;
      updatedAt: string;
      url: string | null;
    }[];
  };
};

const _ListTasksQuery = gql`
  query listTasksQuery {
    listTask {
      items {
        createdAt
        description {
          description
        }
        id
        machine {
          id
          name
        }
        MachineID
        ModelID
        updatedAt
        url(size: "large")
      }
    }
  }
`;

export default function DashboardPage() {
  const [t] = useI18n();
  const user = useContext(UserContext);
  const query = createGraphQLClient(user?.accessToken);

  // { refetch: refetchTasks }
  const [tasksList] = query<TaskList>(_ListTasksQuery);

  // async function fetchTasks(token: string, { value, refetching }) {
  //   if (!token) {
  //     return Promise.reject(new Error("No token"));
  //   }

  //   return request(_ListTasksQuery, ...
  //   });
  // }
  // const [tasksList] = createResource(user?.accessToken, fetchTasks);

  createEffect(() => {
    if (!tasksList) {
      return;
    }
    console.log("tasksList", tasksList());
  });

  return (
    <div class="min-h-full">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {t("dashboard", {}, "Dashboard")}
            </h1>
          </div>
        </header>
        <main>
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="px-4 py-8 sm:px-0">
              <Show
                when={
                  !tasksList.loading &&
                  !tasksList.error &&
                  tasksList()?.listTask
                }
              >
                <For each={tasksList()!.listTask.items}>
                  {(task) => (
                    <TaskOverview
                      createdAt={task.createdAt}
                      description={task.description.description}
                      id={task.id}
                      machine={task.machine}
                      modelId={task.ModelID}
                      updatedAt={task.updatedAt}
                      url={task.url}
                    />
                  )}
                </For>
              </Show>
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  );
}
