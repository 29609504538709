import {
  createEffect,
  createSignal,
  Match,
  Show,
  Suspense,
  Switch,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { useI18n } from "@solid-primitives/i18n";
import { useParams } from "@solidjs/router";
import Carousel from "../../components/Carousel";
import { gql } from "@solid-primitives/graphql";
import { createGraphQLClient, request } from "../../helpers/graphql";
import {
  AnnotationOriginStatus,
  AnnotationTypes,
  DatasetMinimal,
  ImageContainer,
} from "../../types";
import AnnotationComponent from "./components/Annotation";
import { FlagIcon, TagIcon } from "./components/Icons";
import { UserContext } from "../../components/UserContext";

const MAGNIFIER_SIZE = 256;

const _GetTaskQuery = gql`
  query getTaskQuery($getTaskId: ID!) {
    getTask(id: $getTaskId) {
      description {
        description
      }
      model {
        id
        name
        datasets {
          items {
            Dataset {
              annotationType
              classes {
                items {
                  color
                  id
                  name
                }
              }
              id
              images {
                items {
                  annotationComplete
                  annotations {
                    items {
                      annotationData {
                        annotationType
                        singleLabelClassification {
                          label {
                            color
                            id
                            name
                          }
                        }
                      }
                      originStatus
                    }
                  }
                  createdAt
                  id
                  imageKey
                  name
                  source {
                    id
                    name
                  }
                  updatedAt
                  url
                }
              }
              name
            }
          }
        }
      }
    }
  }
`;

const _GetImageContainerQuery = gql`
  query getImageContainerQuery($imageContainerId: ID!) {
    getImageContainer(id: $imageContainerId) {
      annotations {
        items {
          annotationData {
            annotationType
            singleLabelClassification {
              label {
                color
                id
                name
              }
              confidence
            }
          }
          createdAt
          id
          needsReview
          originStatus
          updatedAt
        }
      }
      annotationComplete
      createdAt
      dataset {
        annotationType
        classes {
          items {
            color
            id
            name
          }
        }
        id
        name
      }
      id
      name
      needsReview
      updatedAt
      smallURL: url(size: "small")
      largeURL: url(size: "large")
      originalURL: url(size: "original")
    }
  }
`;

const _InvokeEndpoint = gql`
  mutation InvokeEndpoint($taskId: ID!) {
    invokeEndpoint(taskID: $taskId) {
      images
    }
  }
`;

export default function InspectionPage() {
  const [t, { locale }] = useI18n();
  const params = useParams();
  const user = useContext(UserContext);
  const query = createGraphQLClient(user?.accessToken);
  // const [imageStore, setImageStore] = createStore<{
  //   images: ImageContainer[];
  // }>({ images: [] });
  let magnifierRef!: HTMLDivElement;
  let imageRef!: HTMLImageElement;

  // { refetch: refetchTask }
  const [task] = query<{
    getTask: {
      description: {
        description: string;
      };
      model: {
        id: string;
        name: string;
        datasets: {
          items: {
            Dataset: {
              id: string;
              images: {
                items: ImageContainer[];
              };
              annotationType: AnnotationTypes;
              classes: {
                items: {
                  color: string;
                  id: string;
                  name: string;
                }[];
              };
              createdAt: string;
              updatedAt: string;
              name: string;
            };
          }[];
        };
      };
    };
  }>(_GetTaskQuery, { getTaskId: params.id });

  const [loadInspection, setLoadInspection] = createSignal<boolean | object>(
    false,
  );

  // { refetch: refetchInspection }
  const [inspection] = query<{
    invokeEndpoint: { images: string[] };
  }>(_InvokeEndpoint, loadInspection);

  // const [currentAnnotationId, setCurrentAnnotationId] = createSignal<string>();
  const [currentImageId, setCurrentImageId] = createSignal<string>();
  // const [currentClass, setCurrentClass] = createSignal<string>();
  const [currentDataset, setCurrentDataset] = createSignal<DatasetMinimal>();
  const [imageContainers, setImageContainers] = createStore<ImageContainer[]>(
    [],
  );
  const [imageContainer, { refetch: refetchImageContainer }] = query<{
    getImageContainer: ImageContainer;
  }>(_GetImageContainerQuery, () => {
    console.log("currentImageId", currentImageId());
    return currentImageId() ? { imageContainerId: currentImageId() } : false;
  });

  const preprocessClasses = (dataset: DatasetMinimal | undefined) => {
    if (!dataset) {
      return [];
    }
    const nClasses = dataset.classes.items.length;
    const classes: { color: string; id: string; name: string }[] = [];
    for (let i = 0; i < nClasses; i++) {
      const item = dataset.classes.items[i];
      classes.push({
        color: item.color,
        id: item.id,
        name: item.name,
      });
    }
    return classes;
  };

  const preprocessClassesMap = (dataset: DatasetMinimal | undefined) => {
    if (!dataset) {
      return {};
    }
    const nClasses = dataset.classes.items.length;
    const classes: { [name: string]: string } = {};
    for (let i = 0; i < nClasses; i++) {
      classes[dataset.classes.items[i].name] = dataset.classes.items[i].color;
    }
    return classes;
  };

  createEffect(() => {
    if (task.loading || task.error) {
      setCurrentImageId();
      return;
    }

    const model = task()?.getTask.model;
    const datasets = model?.datasets.items;
    if (!datasets || datasets.length < 1) {
      setCurrentImageId();
      return;
    }

    const images = [];
    for (const dataset of datasets) {
      const items = dataset.Dataset.images.items;
      if (!items || items.length < 1) {
        continue;
      }
      images.push(...items.slice(0, 3));
    }

    // setImageContainers(images);
    // if (!images || images.length < 1) {
    //   setCurrentImageId();
    // } else {
    //   console.log("Setting id to", images[0].id);
    //   setCurrentImageId(images[0].id);
    // }
  });

  createEffect(() => {
    if (inspection.loading || inspection.error) {
      return;
    }

    const imageContainerList = [];
    for (const id of inspection()?.invokeEndpoint?.images ?? []) {
      console.log(id);

      imageContainerList.push(
        request(
          _GetImageContainerQuery,
          {
            imageContainerId: id,
          },
          user?.accessToken,
        ),
      );
    }

    Promise.all(imageContainerList)
      .then((res) => {
        console.log(res);
        const images = [];
        for (const im of res) {
          images.push(im.getImageContainer);
        }
        images.sort(
          (x1, x2) =>
            Number(new Date(x2.createdAt)) - Number(new Date(x1.createdAt)),
        );
        console.log(images);

        setImageContainers(images);
        if (!images || images.length < 1) {
          setCurrentImageId();
        } else {
          console.log("Setting id to", images[0].id);
          setCurrentImageId(images[0].id);
        }
      })
      .catch((err) => console.error("error", err));
  });

  createEffect(() => {
    console.log("dataset", currentDataset());
  });

  createEffect(() => {
    if (
      imageContainer.loading ||
      imageContainer.error ||
      task.loading ||
      task.error
    ) {
      return;
    }

    let found = false;
    const ic = imageContainer()?.getImageContainer;
    if (!ic) {
      return;
    }
    console.log("imageContainer", imageContainer());
    setImageContainers((c: ImageContainer) => c.id === ic.id, ic);

    try {
      const annotations =
        imageContainer?.()?.getImageContainer?.annotations?.items;
      const dataset = imageContainer?.()?.getImageContainer?.dataset;
      console.log("annotations", annotations);
      console.log("dataset x", dataset);

      if (annotations && dataset) {
        setCurrentDataset(dataset);
        for (const a of annotations) {
          const data = a.annotationData;
          if (data.annotationType === dataset.annotationType) {
            // if (data.annotationType === AnnotationTypes.Box) {
            // }
            if (data.annotationType === AnnotationTypes.SingleLabel) {
              console.log("class", data.singleLabelClassification.label.name);
              console.log("annotation id", a.id);
              // setCurrentClass(data.singleLabelClassification.class);
              // setCurrentAnnotationId(a.id);
              found = true;
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }

    if (!found) {
      console.log("not found");
    }
  });

  const carouselSelectImage = (id: string) => {
    console.log(id);
    setCurrentImageId(id);
  };

  const clickedLoadInspection = () => {
    console.log("clicked load inspection");
    setLoadInspection({ taskId: params.id, modelVersion: "v1" });
  };

  const leaveImage = () => {
    magnifierRef.style.display = "none";
  };

  const enterImage = () => {
    magnifierRef.style.display = "block";
  };

  const hoverImage = (e) => {
    const sz = Math.round(MAGNIFIER_SIZE / 2);
    const x = e.offsetX;
    const y = e.offsetY;
    const xpx = Math.round((x / imageRef.width) * imageRef.naturalWidth * 1.5);
    const ypx = Math.round(
      (y / imageRef.height) * imageRef.naturalHeight * 1.5,
    );

    magnifierRef.style.backgroundPositionX = `${-xpx + sz}px`;
    magnifierRef.style.backgroundPositionY = `${-ypx + sz}px`;
    magnifierRef.style.left = `${e.pageX - sz}px`;
    magnifierRef.style.top = `${e.pageY - sz}px`;
    magnifierRef.style.backgroundSize = `${imageRef.naturalWidth * 1.5}px ${
      imageRef.naturalHeight * 1.5
    }px`;
  };

  return (
    <main class="h-full flex flex-col grow bg-gray-500">
      <div class="flex flex-row grow justify-center">
        <div>
          <Show when={imageContainer?.()?.getImageContainer}>
            <span class="font-bold text-lg text-white">
              {t("inspection", {}, "Inspection")}:{" "}
              <Show when={imageContainer?.()?.getImageContainer?.createdAt}>
                {new Intl.DateTimeFormat(
                  locale() === "se" ? "sv-SE" : "en-US",
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  },
                ).format(
                  new Date(
                    imageContainer?.()?.getImageContainer?.createdAt ??
                      Date.now(),
                  ),
                )}
              </Show>
            </span>
          </Show>
          <button
            class="ml-4 nl-button nl-button--base justify-center"
            onClick={clickedLoadInspection}
            type="button"
          >
            <Switch fallback={t("startInspection", {}, "Start Inspection")}>
              <Match when={imageContainer?.()?.getImageContainer}>
                {t("newInspection", {}, "New Inspection")}
              </Match>
            </Switch>
          </button>
        </div>
      </div>
      <div
        classList={{
          "border border-4 h-full flex flex-row justify-end mx-8 rounded-md":
            true,
          "border-nl-gray-900": !imageContainer?.()?.getImageContainer,
          "border-nl-yellow-900": !!imageContainer?.()?.getImageContainer,
        }}
      >
        <Suspense>
          <div
            class="absolute bg-no-repeat border-4 border-black cursor-none h-64 hidden pointer-events-none rounded-xl w-64 z-50"
            id="magnifier"
            ref={magnifierRef}
            style={{
              "background-image":
                "url(" +
                (imageContainer?.()?.getImageContainer?.largeURL
                  ? imageContainer?.()?.getImageContainer.largeURL
                  : "") +
                ")",
            }}
          />
          <div class="border-gray-400 border-opacity-20 border-r-2 flex h-full relative w-full">
            <img
              class="absolute h-full right-0 top-0 w-auto"
              onMouseMove={hoverImage}
              onMouseEnter={enterImage}
              onMouseLeave={leaveImage}
              ref={imageRef}
              src={
                imageContainer?.()?.getImageContainer?.largeURL
                  ? imageContainer?.()?.getImageContainer.largeURL
                  : ""
              }
            />
          </div>
        </Suspense>
        <div class="flex w-[28rem] flex-col gap-4 px-2 shrink-0">
          <div class="flex flex-col mt-4">
            <h3 class="text-xs uppercase font-black text-nl-gray-dark-300">
              {t("annotationTask", {}, "Annotation Task")}:
            </h3>
            <div class="text-lg text-white">
              <Suspense fallback={<div>{t("loading", {}, "Loading")}...</div>}>
                {task?.()?.getTask.description.description ?? "n/a"}
              </Suspense>
            </div>

            <Show when={false}>
              <div class="flex gap-4">
                <button class="flex gap-2 items-center bg-nl-gray-dark-500 hover:bg-nl-gray-dark-700 border border-nl-gray-dark-300 transition text-white px-3 py-2 font-medium text-sm uppercase rounded-md">
                  <TagIcon />
                  Polygon
                </button>
                <button class="flex gap-2 items-center bg-transparent border border-nl-gray-dark-600 hover:bg-nl-gray-dark-700 transition text-nl-gray-dark-100 px-3 py-2 font-medium text-sm uppercase rounded-md">
                  <TagIcon />
                  Box
                </button>
                <button class="flex gap-2 items-center bg-transparent border border-nl-gray-dark-600  hover:bg-nl-gray-dark-700 transition text-nl-gray-dark-100 px-3 py-2 font-medium text-sm uppercase rounded-md">
                  Labeling
                  <TagIcon />
                </button>
              </div>
            </Show>

            <div class="mt-2">
              <Suspense>
                <AnnotationComponent
                  hasHumanAnnotation={(
                    imageContainer?.()?.getImageContainer?.annotations?.items ??
                    []
                  ).some(
                    (e) =>
                      e.originStatus === AnnotationOriginStatus.HumanGenerated,
                  )}
                  annotations={
                    imageContainer?.()?.getImageContainer?.annotations?.items ??
                    []
                  }
                  classes={preprocessClasses(currentDataset())}
                  containerId={imageContainer?.()?.getImageContainer.id}
                  query={query}
                  refetchImageContainer={refetchImageContainer}
                />
              </Suspense>
            </div>

            <Show when={false}>
              <form class="mt-6">
                <fieldset>
                  <legend class="sr-only">Flag</legend>
                  <div class="space-y-5 px-3">
                    <div class="flex gap-2 px-3">
                      <FlagIcon />
                      <div>
                        <div class="text-lg text-nl-gray-dark-400 font-black tracking-wide">
                          {t("flagForReview", {}, "Flag for Review")}
                        </div>
                        {/*
                        <div class="text-xs uppercase text-nl-gray-dark-400 tracking-wide">
                          Your work is saved
                        </div>
                        */}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </Show>
          </div>
        </div>
      </div>
      <div class="h-32 mx-8">
        <Suspense fallback={<div>Loading...</div>}>
          <Carousel
            classes={preprocessClassesMap(currentDataset())}
            currentImageId={currentImageId()}
            images={imageContainers ?? []}
            imageSelectionCallback={carouselSelectImage}
          />
        </Suspense>
      </div>
    </main>
  );
}
