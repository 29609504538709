import {
  createEffect,
  createSignal,
  Match,
  Show,
  Suspense,
  Switch,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { useParams } from "@solidjs/router";
import { useI18n } from "@solid-primitives/i18n";
import { Icon } from "solid-heroicons";
import Carousel from "../../components/Carousel";
import AnnotationComponent from "../Inspection/components/Annotation";
import { flag, tag } from "solid-heroicons/solid";
import { gql } from "@solid-primitives/graphql";
import { createGraphQLClient } from "../../helpers/graphql";
import {
  AnnotationOriginStatus,
  AnnotationTypes,
  GetDatasetType,
  ImageContainer,
} from "../../types";
import { UserContext } from "../../components/UserContext";
import FilledBox from "./components/FilledBox";

const MAGNIFIER_SIZE = 256;

const _GetDatasetQuery = gql`
  query getDatasetQuery($datasetId: ID!) {
    getDataset(id: $datasetId) {
      annotationType
      classes {
        items {
          color
          id
          name
        }
      }
      id
      images(limit: 25) {
        items {
          createdAt
          id
          name
          annotationComplete
          imageKey
          source {
            id
            name
          }
          annotations {
            items {
              id
              annotationData {
                annotationType
                measurement {
                  extraOutputData
                  value
                }
                singleLabelClassification {
                  label {
                    color
                    id
                    name
                  }
                }
              }
              originStatus
            }
          }
          updatedAt
          smallURL: url(size: "small")
          largeURL: url(size: "large")
          originalURL: url(size: "original")
        }
      }
      name
    }
  }
`;

const _GetImageContainerQuery = gql`
  query getImageContainerQuery($imageContainerId: ID!) {
    getImageContainer(id: $imageContainerId) {
      annotations {
        items {
          annotationData {
            annotationType
            measurement {
              extraOutputData
              value
            }
            singleLabelClassification {
              label {
                color
                id
                name
              }
            }
          }
          createdAt
          id
          originStatus
          updatedAt
        }
      }
      annotationComplete
      createdAt
      id
      name
      updatedAt
      smallURL: url(size: "small")
      largeURL: url(size: "large")
      originalURL: url(size: "original")
    }
  }
`;

function FlagIcon() {
  return <Icon aria-hidden="true" class="text-gray-300 h-8 w-8" path={flag} />;
}

function TagIcon() {
  return <Icon class="h-4 w-4" path={tag} />;
}

export default function AnnotateDatasetPage() {
  const params = useParams();
  const [t] = useI18n();
  const user = useContext(UserContext);
  const query = createGraphQLClient(user?.accessToken);
  let magnifierRef!: HTMLDivElement;
  let imageRef!: HTMLImageElement;

  // { refetch: refetchDataset }
  const [dataset] = query<GetDatasetType>(_GetDatasetQuery, {
    datasetId: params.id,
  });

  const preprocessClasses = (dataset: GetDatasetType | undefined) => {
    if (!dataset) {
      return [];
    }
    const nClasses = dataset.getDataset.classes.items.length;
    const classes: { color: string; id: string; name: string }[] = [];
    for (let i = 0; i < nClasses; i++) {
      const item = dataset.getDataset.classes.items[i];
      classes.push({
        color: item.color,
        id: item.id,
        name: item.name,
      });
    }
    return classes;
  };

  const preprocessClassesMap = (dataset: GetDatasetType | undefined) => {
    if (!dataset) {
      return {};
    }
    const nClasses = dataset.getDataset.classes.items.length;
    const classes: { [name: string]: string } = {};
    for (let i = 0; i < nClasses; i++) {
      classes[dataset.getDataset.classes.items[i].name] =
        dataset.getDataset.classes.items[i].color;
    }
    return classes;
  };

  const [currentImageId, setCurrentImageId] = createSignal<string>();
  const [imageContainers, setImageContainers] = createStore<ImageContainer[]>(
    [],
  );
  const [imageContainer, { refetch: refetchImageContainer }] = query<{
    getImageContainer: ImageContainer;
  }>(_GetImageContainerQuery, () => {
    console.log("currentImageId", currentImageId());
    return currentImageId() ? { imageContainerId: currentImageId() } : false;
  });

  createEffect(() => {
    if (dataset.loading || dataset.error) {
      setCurrentImageId();
      return;
    }

    const items = dataset()?.getDataset.images.items;
    if (!items || items.length < 1) {
      setCurrentImageId();
      return;
    }
    items.sort(
      (x1, x2) =>
        Number(new Date(x2.createdAt)) - Number(new Date(x1.createdAt)),
    );
    setImageContainers(items);

    console.log("Setting id to", items[0].id);
    setCurrentImageId(items[0].id);
  });

  createEffect(() => {
    if (
      imageContainer.loading ||
      imageContainer.error ||
      dataset.loading ||
      dataset.error
    ) {
      return;
    }
    const ic = imageContainer()?.getImageContainer;
    if (!ic) {
      // setCurrentClass();
      // setCurrentAnnotationId();
      return;
    }
    console.log("effect", imageContainer());

    setImageContainers((c: ImageContainer) => c.id === ic.id, ic);
  });

  createEffect(() => {
    console.log(imageContainers);
  });

  const carouselSelectImage = (id: string) => {
    console.log(id);
    setCurrentImageId(id);
  };

  const leaveImage = () => {
    magnifierRef.style.display = "none";
  };

  const enterImage = () => {
    magnifierRef.style.display = "block";
  };

  const hoverImage = (e: any) => {
    const sz = Math.round(MAGNIFIER_SIZE / 2);
    const x = e.offsetX;
    const y = e.offsetY;
    const xpx = Math.round((x / imageRef.width) * imageRef.naturalWidth * 1.5);
    const ypx = Math.round(
      (y / imageRef.height) * imageRef.naturalHeight * 1.5,
    );

    magnifierRef.style.backgroundPositionX = `${-xpx + sz}px`;
    magnifierRef.style.backgroundPositionY = `${-ypx + sz}px`;
    magnifierRef.style.left = `${e.pageX - sz}px`;
    magnifierRef.style.top = `${e.pageY - sz}px`;
    magnifierRef.style.backgroundSize = `${imageRef.naturalWidth * 1.5}px ${
      imageRef.naturalHeight * 1.5
    }px`;
  };

  return (
    <main class="flex flex-col grow bg-gray-500">
      <div class="flex flex-row grow justify-end">
        <Suspense>
          <div
            class="absolute bg-no-repeat border-4 border-black cursor-none h-64 hidden pointer-events-none rounded-xl w-64 z-50"
            id="magnifier"
            ref={magnifierRef}
            style={{
              "background-image":
                "url(" +
                (imageContainer?.()?.getImageContainer?.largeURL
                  ? imageContainer?.()?.getImageContainer.largeURL
                  : "") +
                ")",
              "background-size": "200% 200%",
            }}
          />
          <div class="border-gray-400 border-opacity-20 border-r-2 flex h-full relative w-full">
            <img
              class="absolute h-full right-0 top-0 w-auto"
              onMouseMove={hoverImage}
              onMouseEnter={enterImage}
              onMouseLeave={leaveImage}
              ref={imageRef}
              src={
                imageContainer?.()?.getImageContainer?.largeURL
                  ? imageContainer?.()?.getImageContainer.largeURL
                  : ""
              }
            />
          </div>
        </Suspense>
        <div class="flex w-[28rem] flex-col gap-4 shrink-0 px-3">
          <div class="flex flex-col mt-4">
            <h3 class="text-2xs uppercase font-black text-nl-gray-dark-300">
              {t("annotationTask", {}, "Annotation Task")}:
            </h3>
            <div class="text-lg text-white">
              <Suspense fallback={<div>{t("loading", {}, "Loading")}...</div>}>
                {dataset?.()?.getDataset.name ?? "n/a"}
              </Suspense>
            </div>
          </div>

          <Suspense>
            <Show
              when={
                imageContainer?.()?.getImageContainer?.annotations?.items ?? []
              }
            >
              <div>
                <Switch>
                  <Match
                    when={
                      dataset?.()?.getDataset.annotationType ===
                      AnnotationTypes.Measurement
                    }
                  >
                    <div class="text-md text-white uppercase">
                      <span class="text-sm font-semibold mr-2 text-nl-gray-dark-200">
                        Fill level:
                      </span>
                      {(imageContainer?.()?.getImageContainer?.annotations
                        ?.items?.[0].annotationData.measurement?.value ?? 0.1) * 100} %
                    </div>
                    <FilledBox levels={JSON.parse(imageContainer?.()?.getImageContainer?.annotations?.items?.[0].annotationData.measurement?.extraOutputData ?? "{}")} />
                  </Match>
                  <Match
                    when={dataset?.()?.getDataset.annotationType === AnnotationTypes.SingleLabel}
                  >
                    {/* Only show AI annotation if lacking human annotation */}
                    <AnnotationComponent
                      hasAiAnnotation={(
                        imageContainer?.()?.getImageContainer?.annotations?.items ??
                        []
                      ).some(
                        (e) =>
                          e.originStatus !== AnnotationOriginStatus.HumanGenerated,
                      )}
                      hasHumanAnnotation={(
                        imageContainer?.()?.getImageContainer?.annotations?.items ??
                        []
                      ).some(
                        (e) =>
                          e.originStatus === AnnotationOriginStatus.HumanGenerated,
                      )}
                      annotations={
                        imageContainer?.()?.getImageContainer?.annotations?.items ??
                        []
                      }
                      classes={preprocessClasses(dataset?.())}
                      containerId={imageContainer?.()?.getImageContainer.id}
                      query={query}
                      refetchImageContainer={refetchImageContainer}
                    />
                  </Match>
                </Switch>
              </div>
            </Show>
          </Suspense>
        </div>
      </div>
      <div class="h-32">
        <Suspense fallback={<div>{t("loading", {}, "Loading")}...</div>}>
          <Carousel
            classes={preprocessClassesMap(dataset?.())}
            currentImageId={currentImageId()}
            images={imageContainers ?? []}
            imageSelectionCallback={carouselSelectImage}
          />
        </Suspense>
      </div>
    </main>
  );
}
