import { Auth } from "aws-amplify";
import { createSignal, onMount } from "solid-js";
import { useNavigate } from "@solidjs/router";

export function LandingPage() {
  const [user, setUser] = createSignal("");
  const navigate = useNavigate();

  async function signOut() {
    try {
      await Auth.signOut();
      console.log("redirecting to /");
      navigate("/", { replace: true });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  onMount(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => setUser(userData.username))
      .catch((err) => {
        console.log(err);
        console.log("redirecting to /");
        navigate("/", { replace: true });
      });
  });

  return (
    <div>
      <h1>Hello {user()}</h1>
      <button class="border-2 shadow-md active:bg-amber-100" onClick={signOut}>
        Logout
      </button>
    </div>
  );
}
