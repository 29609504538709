import {
  createGraphQLClient as gqlClient,
  request as gqlRequest,
} from "@solid-primitives/graphql";

export function createGraphQLClient(token: string | undefined) {
  const api_url = import.meta.env.VITE_API_URL;
  if (!api_url) {
    throw new Error("GraphQL REST API URL missing (VITE_API_URL)");
  } else if (!token || typeof token !== "string") {
    throw new Error("GraphQL API token missing");
  }

  const query = gqlClient(api_url, {
    headers: {
      auth_token: token,
      auth_type: "USER",
    },
  });

  return query;
}

export function request(
  gqlQuery: string,
  inputVars: object,
  token: string | undefined,
) {
  const api_url = import.meta.env.VITE_API_URL;
  if (!api_url) {
    throw new Error("GraphQL REST API URL missing (VITE_API_URL)");
  } else if (!token || typeof token !== "string") {
    throw new Error("GraphQL API token missing");
  }

  return gqlRequest(api_url, gqlQuery, {
    headers: {
      auth_token: token,
      auth_type: "USER",
    },
    variables: inputVars,
  });
}
