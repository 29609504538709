import { For, mergeProps, Show, type Component } from "solid-js";
import { createFormControl, type IFormControl } from "solid-forms";

export const CheckboxInput: Component<{
  class?: string;
  control?: IFormControl<string>;
  name?: string;
}> = (_props) => {
  // here we provide a default form control in case the user doesn't supply one
  const props = mergeProps({ control: createFormControl("") }, _props);

  return (
    <div
      classList={{
        "is-invalid": !!props.control.errors,
        "is-touched": props.control.isTouched,
        "is-required": props.control.isRequired,
      }}
    >
      <input
        class={props.class}
        name={props.name}
        type="checkbox"
        value={props.control.value}
        onInput={(e: InputEvent) => {
          const element = e.currentTarget as HTMLInputElement;
          props.control.setValue(element.value);
        }}
        onBlur={() => props.control.markTouched(true)}
        required={props.control.isRequired}
      />

      <Show when={props.control.isTouched && !!props.control.errors}>
        <For each={Object.values(props.control.errors || {})}>
          {(errorMsg) => <div class="text-red-500 text-sm">{errorMsg}</div>}
        </For>
      </Show>
    </div>
  );
};
