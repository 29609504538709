import { onMount } from "solid-js";

interface Props {
  reset: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

function OuterErrorHandler(props: Props) {
  onMount(() => {
    console.error(props.error);
  });

  return (
    <>
      <h1>Error</h1>
      <div>{props.error.toString()}</div>
      <button
        class="btn btn-blue"
        onClick={props.reset} /* eslint-disable-line solid/reactivity */
      >
        Reset?
      </button>
    </>
  );
}

export default OuterErrorHandler;
